import { createMuiTheme } from "@material-ui/core/styles"


const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            //main: "#f9a825"
            //main: "#0086c3",
            //main: "#ff1744",
            //main: "#d32f2f"
            //main: "#005ee5"
            main: "#49c6e5",
        },
        secondary: {
            main: "#5eb8ff",
        },
        background: {
            default: "#080808",
        },
    },
    typography: {
        fontFamily: [
            "Open Sans",
            "-apple-system",
            "system-ui",
            "Segoe UI",
            "Roboto",
            "Helvetica Neue",
            "Arial",
            "Noto Sans",
            "sans-serif",
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
            "Noto Color Emoji",
        ].join(","),
        h1: {
            fontFamily: "Kumbh Sans",
            fontSize: "4.5em",
            fontWeight: 700,
        },
        h2: {
            fontFamily: "Kumbh Sans",
            fontSize: "2.5em",
        },
        h3: {
            fontFamily: "Kumbh Sans",
            fontSize: "2.3em",
            fontWeight: 700,
            marginTop: 12,
            marginBottom: 24,
        },
        h6: {
            fontFamily: "Kumbh Sans",
            fontSize: "1.2em",
            fontWeight: 700,
        },
        body1: {
            fontFamily: "Open Sans",
        },
        button: {
            fontFamily: "Open Sans",
            fontSize: "0.8em",
            fontWeight: 600,
            letterSpacing: "0.1em",
        },
        overline: {
            fontFamily: "Open Sans",
            fontWeight: 600,
            letterSpacing: "0.1em",
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
            },
        },
    },
})

export default theme
